import { required } from 'vuelidate/lib/validators'

export default {
  computed: {
    isAndroidFormValid() {
      return !this.$v.androidParams.$anyError
    },

    isIOSFormValid() {
      return !this.$v.iosParams.$anyError
    }
  },

  validations: {
    iosParams: {
      version: { required }
    },

    androidParams: {
      version: { required }
    }
  }
}
