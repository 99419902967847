<template>
  <div>
    <div class="box box--white mt-24">
      <h3 class="headline--md text-bold">App Version Management</h3>

      <div v-if="platform.ios" class="d-flex align-end mt-12">
        <div>
          <p class="text-bold">Current iOS version: {{ platform.ios.version }}</p>
          <p class="text-sm text-warm">Last update: {{ platform.ios.updatedAt | moment_lts }}</p>
        </div>

        <div v-if="$auth('Page', currentUser, $accessRule.PlatformSettingUpdate).canView()" class="d-flex align-end">
          <div class="form-group mr-8">
            <label class="form-label d-block">New iOS app version</label>
            <input v-model="$v.iosParams.version.$model" type="text" class="form-control form-control--md" />
          </div>

          <button
            class="btn btn--md btn--primary"
            :disabled="!isIOSFormValid"
            @click="isOpenConfirmIOSChangeVersion = true"
          >
            Submit
          </button>
        </div>
      </div>

      <div class="divider mt-32 mb-32"></div>

      <div v-if="platform.android" class="d-flex align-end">
        <div>
          <p class="text-bold">Current Android version: {{ platform.android.version }}</p>
          <p class="text-sm text-warm">Last update: {{ platform.android.updatedAt | moment_lts }}</p>
        </div>

        <div v-if="$auth('Page', currentUser, $accessRule.PlatformSettingUpdate).canView()" class="d-flex align-end">
          <div class="form-group mr-8">
            <label class="form-label d-block">New Android app version</label>
            <input v-model="$v.androidParams.version.$model" type="text" class="form-control form-control--md" />
          </div>

          <button
            class="btn btn--md btn--primary"
            :disabled="!isAndroidFormValid"
            @click="isOpenConfirmAndroidChangeVersion = true"
          >
            Submit
          </button>
        </div>
      </div>
    </div>

    <confirm-dialog
      v-if="$auth('Page', currentUser, $accessRule.PlatformSettingUpdate).canView() && isOpenConfirmIOSChangeVersion"
      :open.sync="isOpenConfirmIOSChangeVersion"
      confirmText="Are you sure you would like to submit a new iOS app version?"
      confirmButtonText="Submit"
      confirmButtonClass="text-purple"
      @confirm="onUpdateAppVersion({ platformID: 1 })"
    />

    <confirm-dialog
      v-if="
        $auth('Page', currentUser, $accessRule.PlatformSettingUpdate).canView() && isOpenConfirmAndroidChangeVersion
      "
      :open.sync="isOpenConfirmAndroidChangeVersion"
      confirmText="Are you sure you would like to submit a new Android app version?"
      confirmButtonText="Submit"
      confirmButtonClass="text-purple"
      @confirm="onUpdateAppVersion({ platformID: 2 })"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import appVersionFormValidator from '@/validations/app-version-form'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  data() {
    return {
      isOpenConfirmIOSChangeVersion: false,
      isOpenConfirmAndroidChangeVersion: false,
      iosParams: null,
      androidParams: null
    }
  },

  mixins: [appVersionFormValidator],

  components: {
    ConfirmDialog
  },

  computed: {
    ...mapState('setting', ['platform'])
  },

  methods: {
    ...mapActions('setting', ['getAppVersions', 'updateAppVersion']),

    closeConfirmDialog() {
      this.isOpenConfirmIOSChangeVersion = false
      this.isOpenConfirmAndroidChangeVersion = false
    },

    initPlatformParams() {
      this.iosParams = { ...this.platform.ios, version: '' }
      this.androidParams = { ...this.platform.android, version: '' }
    },

    onUpdateAppVersion(data) {
      const targetPlatform = data.platformID
      if (!targetPlatform) return

      const param = targetPlatform === 1 ? this.iosParams : this.androidParams
      if (!param.version) return alert('Please input value!')

      this.updateAppVersion(param).then(() => {
        this.initPlatformParams()
        this.closeConfirmDialog()
      })
    }
  },

  async created() {
    await this.getAppVersions()
    this.initPlatformParams()
    this.$v && this.$v.$touch()
  }
}
</script>
